<template>
	<div class="pui-form-layout">
		<v-row>
			<v-col cols="12">
				<pui-field-set :title="$t('attribute.custom')">
					<v-row dense>
						<v-col cols="4">
							<pui-text-field
								:id="`description-attribute`"
								v-model="model.description"
								:label="$t('attribute.description')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="4">
							<pui-select
								id="typeattributeid"
								attach="typeattributeid"
								:label="$t('attribute.typeattributeid')"
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="attributetype"
								:itemsToSelect="[{ id: model.typeattributeid }]"
								:modelFormMapping="{ id: 'typeattributeid' }"
								itemValue="id"
								itemText="description"
								toplabel
								reactive
							></pui-select>
						</v-col>
						<v-col cols="4">
							<pui-text-field
								:id="`identify-attribute`"
								v-model="model.identify"
								:label="$t('attribute.identify')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="50"
							></pui-text-field>
						</v-col>
						<v-col cols="3">
							<pui-spinner-field
								:id="`${modelName}-sort`"
								:label="$t('attribute.sort')"
								v-model.number="model.sort"
								maxlength="4"
								min="0"
								max="9999"
								toplabel
								:disabled="formDisabled"
								required
							></pui-spinner-field>
						</v-col>
						<v-col cols="2">
							<pui-checkbox
								:id="`disabled`"
								:label="$t('attribute.active')"
								:true-value="0"
								v-model="model.disabled"
								:false-value="1"
								:disabled="formDisabled"
							></pui-checkbox>
						</v-col>
						<v-col cols="2">
							<pui-checkbox
								:id="`required`"
								:label="$t('attribute.required')"
								:true-value="1"
								v-model="model.required"
								:false-value="0"
								:disabled="formDisabled"
							></pui-checkbox>
						</v-col>
						<v-col cols="4" v-if="model.typeattributeid === 7">
							<pui-select
								id="modeldata"
								attach="modeldata"
								:label="$t('attribute.modeldata')"
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="puimodelpa"
								:itemsToSelect="[{ model: model.modeldata }]"
								:modelFormMapping="{ model: 'modeldata' }"
								itemValue="model"
								itemText="description"
								toplabel
								reactive
								:key="modeldatakey"
							></pui-select>
						</v-col>
					</v-row>
				</pui-field-set>
			</v-col>
			<v-col cols="12" lg="6" xl="5" v-if="model.typeattributeid === 5">
				<pui-field-set :title="$t('attribute.optionslist')">
					<draggable :list="model.typeattributeoptions" ghost-class="ghost" @start="dragging = true" @end="dragging = false">
						<template v-for="(option, index) in model.typeattributeoptions">
							<div :key="option[index]">
								<p style="display: none">{{ calculateSort(index) }}</p>
								<v-row dense style="height: 40px; margin-bottom: 18px !important">
									<v-col cols="1">
										<v-list-item class="move draggable_point mt-n2">
											<v-icon small>fa fa-arrows</v-icon>
										</v-list-item>
									</v-col>
									<v-col cols="9" sm="8" xl="6">
										<pui-text-field
											:id="`select-${index}-${modelName}`"
											v-model="option.description"
											maxlength="100"
											required
										></pui-text-field>
									</v-col>
									<v-col cols="1" v-if="index !== 0">
										<v-btn
											title="borrar"
											depressed
											color="danger"
											class="elevation-0 option"
											@click.native="removeDetail(index)"
											icon
										>
											<v-icon small class="pointer">fas fa-trash</v-icon>
										</v-btn>
									</v-col>
									<v-col cols="1" v-if="index === model.typeattributeoptions.length - 1">
										<v-btn
											title="crear"
											depressed
											color="success"
											class="elevation-0 option"
											@click.native="addDetail(index)"
											icon
										>
											<v-icon small class="pointer">fas fa-plus</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</div>
						</template>
					</draggable>
				</pui-field-set>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'attributequickform',
	mixins: [],
	components: {},
	data() {
		return {
			modelName: 'attribute',
			modeldatakey: 0
		};
	},
	props: {
		model: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		parentModel: {
			type: Object,
			required: true
		}
	},
	methods: {
		afterGetData() {
			if (this.$router.currentRoute.path) {
				this.model.model = this.$router.currentRoute.path.split('/')[1];
			}
			if (this.model.typeattributeoptions.length == 0) {
				this.model.typeattributeoptions = [{}];
				this.addDetail(0);
			}
			console.log(this.model);
		},
		removeDetail(index) {
			this.model.typeattributeoptions.splice(index, 1);
			if (this.model.typeattributeoptions.length === 0) {
				this.addDetail(index);
			}
		},
		addDetail(index) {
			this.model.typeattributeoptions.push({ sort: index++ });
		},
		calculateSort(index) {
			this.model.typeattributeoptions[index].sort = index + 1;
			return this.model.typeattributeoptions[index].sort;
		}
	},
	computed: {},
	created() {},
	watch: {
		'model.typeattributeid'(newVal) {
			if (newVal !== 5) {
				this.model.typeattributeoptions = [{}];
			} else if (this.model.typeattributeoptions.length == 0) {
				this.model.typeattributeoptions = [{}];
			}
		},
		'model.sort'(newVal) {
			if (!newVal) {
				this.model.sort = 0;
			}
		},
		'model.typeattributeoptions'() {
			if (this.model.typeattributeoptions.length == 0) {
				this.addDetail(0);
			}
		},
		model() {
			this.modeldatakey++;
		}
	}
};
</script>
